.headerBackground {
  width: 100%;
  height: 400px;
  backdrop-filter: blur(10px);
  position: absolute;
}

.aws-btn__content {
  background-color: #F6828C !important;
  width: 200px;
  font-family: "Bebas Neue";
  font-size: 2.3rem;
}

.portfolioCard:hover {
  border-color: #F6828C;
  cursor: pointer;
}

.aws-btn__content::before {
  background: none !important;
}

.aws-btn__wrapper::before {
  background: none !important;
}

.featureCard {
  transition: all ease-out 0.3s;
}

.featureCard:hover {
  transform: scale(1.1);
}
