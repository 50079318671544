body, html, #root {
    background-color: #050517;
    color: #FEFCFD !important;
}

a {
    color: #77567A;
}

.MuiPaper-root {
    background-color: #01010a !important;
    color: #FEFCFD !important;
}
